var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('div',{staticClass:"overlayLoader"},[_c('md-progress-spinner',{staticClass:"md-accent spinner-postion",attrs:{"md-diameter":90,"md-stroke":7,"md-mode":"indeterminate"}})],1):_vm._e(),_c('b-row',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-60"},[_c('b-alert',{attrs:{"variant":_vm.isSuccess ? 'info' : 'danger',"show":_vm.showAlert,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.showAlert = false}}},[_vm._v(_vm._s(_vm.respMessage)+" ")])],1)]),_vm._t("default"),(
        _vm.approval_status !== 'APPROVED' &&
          _vm.approval_status !== 'INPROCESS' &&
          _vm.formName !== 'rfp' &&
          _vm.formName !== 'rfpItems' &&
          _vm.formName !== 'rfpBoq' &&
          _vm.formName !== 'rfpQuotationView'
      )?_c('b-row',[_c('b-col',[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","id":"FileUpload1"},on:{"change":function($event){return _vm.selectFile()}}}),_c('md-button',{staticClass:"md-info",staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.browseFile()}}},[_vm._v("Add Files..")]),_c('md-button',{staticClass:"md-warning",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v("Start Upload")])],1)],1):_vm._e(),_c('div',{staticClass:"md-layout-item",staticStyle:{"margin-top":"5px !important"}},[_c('b-table',{staticClass:"bg-white font-fix",attrs:{"striped":"","hover":"","outlined":"","items":_vm.uploadDocList,"fields":_vm.uploadDocFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(file_name)",fn:function(data){return [(data.item.docDetailId !== 0)?_c('a',{attrs:{"target":"_blank","href":_vm.downloadDocUrl +
                '/download/' +
                data.item.docDetailId +
                '?token=' +
                _vm.openKmAuth}},[_vm._v(" "+_vm._s(data.item.file_name))]):_c('span',[_vm._v(_vm._s(data.item.file_name))])]}},{key:"cell(preview)",fn:function(data){return [_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":function($event){return _vm.getOpenKmAuthenticatePreview(
                data.item.docDetailId,
                data.item.file_name,
                data.index,
                _vm.uploadDocList
              )}}},[(data.item.docDetailId)?_c('span',{staticClass:"material-icons"},[_vm._v(" preview ")]):_vm._e()])]}},{key:"cell(leName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(data.item.leName),callback:function ($$v) {_vm.$set(data.item, "leName", $$v)},expression:"data.item.leName"}})]}},{key:"cell(categoryName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(data.item.categoryName),callback:function ($$v) {_vm.$set(data.item, "categoryName", $$v)},expression:"data.item.categoryName"}})]}},{key:"cell(subcategoryName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},on:{"click":function($event){return _vm.openValueSetModal('OKM_SUB_CAT_DOC', data.index)}},model:{value:(data.item.subcategoryName),callback:function ($$v) {_vm.$set(data.item, "subcategoryName", $$v)},expression:"data.item.subcategoryName"}})]}},{key:"cell(comments)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","disabled":data.item.docDetailId !== 0},model:{value:(data.item.comments),callback:function ($$v) {_vm.$set(data.item, "comments", $$v)},expression:"data.item.comments"}})]}},(
            _vm.formName !== 'rfp' &&
              _vm.formName !== 'rfpItems' &&
              _vm.formName !== 'rfpBoq' &&
              _vm.formName !== 'rfpQuotation'
          )?{key:"cell(delete)",fn:function(data){return [(data.item.docDetailId)?_c('div',{on:{"click":function($event){return _vm.deleteRow(data.index, data.item.docDetailId)}}},[_c('md-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("delete")])],1):_c('div',{on:{"click":function($event){return _vm.deleteTempRow(data.index)}}},[_c('md-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("delete")])],1)]}}:null],null,true)})],1),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.formName == 'invoice_entry')?_c('div',{staticStyle:{"text-align":"right","margin-top":"20%"}},[_c('span',{staticStyle:{"color":"red"}},[_c('b',[_vm._v("*Maximum file size 15 MB")]),_c('br'),_c('b',[_vm._v("*only pdf, jpg, jpeg, png, zip, excel file allowed")])])]):_vm._e(),(_vm.formName === 'rfpQuotation')?_c('div',{staticStyle:{"text-align":"right","margin-top":"20%"}},[_c('span',{staticStyle:{"color":"red"}},[_c('b',[_vm._v("*Maximum file size 20 MB")]),_c('br'),_c('b',[_vm._v("*only pdf, jpg, jpeg, png, zip, excel file allowed")])])]):_vm._e(),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","size":"xl","title":"Preview"},on:{"hidden":function($event){return _vm.showHideUploadDocModal(false)}},model:{value:(_vm.showOpenUploadDocModal),callback:function ($$v) {_vm.showOpenUploadDocModal=$$v},expression:"showOpenUploadDocModal"}},[_c('previewDoc',{attrs:{"authToken":_vm.authTokenPreview,"docDtlId":_vm.docDtlId,"uploadedFileName":_vm.uploadedFileName,"currentPageIndex":_vm.currentPageIndex,"data":_vm.dynamicData}})],1),(_vm.showValueSetModal)?_c('valueSetModal',{on:{"selectedvalueSet":_vm.selectedvalueSet,"closeValueSetModal":_vm.closeValueSetModal}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }