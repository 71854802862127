import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import PartyDashboard from "@/pages/Dashboard/PartyPortal/PartyDashboard.vue";
// Profile
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";

// User Management
import ListUserPage from "@/pages/Dashboard/Examples/UserManagement/ListUserPage.vue";

// Pages
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";

// Components pages
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";

// Maps pages
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

import Party from "@/pages/Dashboard/Party/PartyDetails.vue";
import PartyDetails from "@/pages/Dashboard/PartyPortal/Party/PartyDetails.vue";
import UpdatePartyInfo from "@/pages/Dashboard/Party/UpdatePartyInfo.vue";
import VendorInvoice from "@/pages/Dashboard/Vendor/VendorInvoice.vue";
import CustomerDashboard from "@/pages/Dashboard/Customer/CustomerDashboard.vue";
import UnitDetails from "@/pages/Dashboard/Customer/UnitDetails.vue";
import CustomerQuery from "@/pages/Dashboard/Customer/CustomerQuery.vue";
import PoDetails from "@/pages/Dashboard/Vendor/PoDetails.vue";
import TDSCertificate from "@/pages/Dashboard/Vendor/TDSCertificate.vue";
import NewInvoiceEntry from "@/pages/Dashboard/Vendor/NewInvoiceEntry.vue";
import TDSCustomerCertificate from "@/pages/Dashboard/Customer/TdsCertificate/TdsCertificate.vue";
import ChangePassword from "@/pages/Dashboard/Settings/ChangePassword.vue";
import Ledger from "@/pages/Dashboard/Customer/Ledger.vue";
import VendorLedger from "@/pages/Dashboard/Vendor/VendorLedger.vue";
import comingSoon from "@/pages/Dashboard/comingSoon.vue";
import VendorUnitDetails from "@/pages/Dashboard/Vendor/VendorUnitDetails.vue";
import PartyOnboarding from "@/pages/Dashboard/Vendor/PartyOnboarding.vue";
import VendorPayment from "@/pages/Dashboard/Vendor/VendorPayment.vue";
import VendorInvoiceEntry from "@/pages/Dashboard/Vendor/VendorInvoiceEntry.vue";
import AdvanceReport from "@/pages/Dashboard/Vendor/AdvanceReport.vue";
import Routing from "@/pages/Dashboard/Routing.vue";
import PartyVendorUnitDetails from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyVendorUnitDetails.vue";
import PartyVendorLedger from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyVendorLedger.vue";
import PartyVendorInvoice from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyVendorInvoice.vue";
import PartyNewInvoiceEntry from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyNewInvoiceEntry.vue";
import AdminPage from "@/pages/Dashboard/Vendor/AdminPage.vue";
import PartyVendorSecurityDeposit from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyVendorSecurityDeposit.vue";
import PartyVendorRecoveryInvoice from "@/pages/Dashboard/PartyPortal/PartyVendor/PartyVendorRecoveryInvoice.vue";
import RFP from "@/pages/Dashboard/Vendor/RFP.vue";
import UserManagement from "../pages/Dashboard/Vendor/UserManagement.vue"

let partyMenu = {
  path: "/party",
  component: DashboardLayout,
  redirect: "/components/notification",
  name: "Components",
  children: [
    {
      path: "party-details",
      name: "Party Details",
      components: { default: Party },
      meta: { middleware: auth },
    },
    {
      path: "update-info",
      name: "Update Party Info",
      components: { default: UpdatePartyInfo },
      meta: { middleware: auth },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: { middleware: auth },
    },
    {
      path: "maps",
      name: "Maps",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
        middleware: auth,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
        middleware: auth,
      },
      components: { default: RtlSupport },
    },
  ],
};
let vendorsMenu = {
  path: "/vendor",
  component: DashboardLayout,
  redirect: "/vendor/invoice",
  name: "Components",
  children: [
    {
      path: "invoice",
      name: "Vendor Invoice",
      components: { default: VendorInvoice },
      meta: { middleware: auth },
    },
    {
      path: "unit-details",
      name: "Unit Details",
      components: { default: VendorUnitDetails },
      meta: { middleware: auth },
    },
    {
      path: "ledger",
      name: "Vendor Ledger",
      components: { default: VendorLedger },
      meta: { middleware: auth },
    },
    {
      path: "update-info",
      name: "Update Party Info",
      components: { default: Party },
      meta: { middleware: auth },
    },
    {
      path: "po-details",
      name: "PO Details",
      components: { default: PoDetails },
      meta: { middleware: auth },
    },
    {
      path: "tds-certificate",
      name: "TDS Certificate",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "new-invoice-entry",
      name: "New Invoice Entry",
      components: { default: NewInvoiceEntry },
      meta: { middleware: auth },
    },
    {
      path: "asn",
      name: "ASN",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "sourcing",
      name: "Sourcing",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "party-Onboarding",
      name: "Party Onboarding",
      components: { default: PartyOnboarding },
      meta: { middleware: auth },
    },
    {
      path: "payment",
      name: "Vendor Payment",
      components: { default: VendorPayment },
      meta: { middleware: auth },
    },
    {
      path: "invoice-entry",
      name: "Vendor Invoice Entry",
      components: { default: VendorInvoiceEntry },
      meta: { middleware: auth },
    },
    {
      path: "advance-report",
      name: "Advance Report",
      components: { default: AdvanceReport },
      meta: { middleware: auth },
    },
    {
      path: "admin-page",
      name: "Admin Page",
      components: { default: AdminPage },
      meta: { middleware: auth },
    },
    {
      path: "rfp",
      name: "RFP",
      components: { default: RFP },
      meta: { middleware: auth },
    },
    {
      path: "user-management",
      name: "User Management",
      components: { default: UserManagement },
      meta: { middleware: auth },
    },
  ],
};
let partyVendorsMenu = {
  path: "/partyPortal",
  component: DashboardLayout,
  redirect: "/partyVendor",
  name: "Components",
  children: [
    {
      path: "/vendor-invoice",
      name: "Vendor Invoice",
      components: { default: PartyVendorInvoice },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-unit-details",
      name: "Unit Details",
      components: { default: PartyVendorUnitDetails },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-security-deposit",
      name: "Security Deposit",
      components: { default: PartyVendorSecurityDeposit },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-ledger",
      name: "Vendor Ledger",
      components: { default: PartyVendorLedger },
      meta: { middleware: auth },
    },
    // {
    //   path: "update-info",
    //   name: "Update Party Info",
    //   components: { default: Party },
    //   meta: { middleware: auth },
    // },
    {
      path: "/vendor-po-details",
      name: "PO Detaiils",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-tds-certificate",
      name: "TDS Certificate",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "/new-invoice-entry",
      name: "New Invoice Entry",
      components: { default: PartyNewInvoiceEntry },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-asn",
      name: "ASN",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-sourcing",
      name: "Sourcing",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "/vendor-recovery-invoice",
      name:"Recovery Invoice",
      components :{default : PartyVendorRecoveryInvoice},
      meta : {middleware :auth}
     
    }
  ],
};
let customersMenu = {
  path: "/customer",
  component: DashboardLayout,
  redirect: "/customer/dashboard",
  name: "Components",
  children: [
    {
      path: "dashboard",
      name: "Customer Dashboard",
      components: { default: CustomerDashboard },
      meta: { middleware: auth },
    },
    {
      path: "unit-details",
      name: "Unit Details",
      components: { default: UnitDetails },
      meta: { middleware: auth },
    },
    {
      path: "ledger",
      name: "Ledger",
      components: { default: Ledger },
      meta: { middleware: auth },
    },
    {
      path: "query",
      name: "Customer Query",
      components: { default: CustomerQuery },
      meta: { middleware: auth },
    },
    {
      path: "tds-certificate",
      name: "TDS Certificate",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "mutation",
      name: "Mutation",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "dunning-notice",
      name: "Dunning Notice",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
  ],
};
let settingsMenu = {
  path: "/settings",
  component: DashboardLayout,
  redirect: "/settings/dashboard",
  name: "Components",
  children: [
    {
      path: "change-password",
      name: "Change Password",
      components: { default: ChangePassword },
      meta: { middleware: auth },
    },
  ],
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth },
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: auth },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest },
    },
  ],
};

let partyPortalMenu = {
  path: "/partyPortal",
  component: DashboardLayout,
  redirect: "/components/notification",
  name: "Components",
  children: [
    {
      path: "party-details",
      name: "Party Details",
      components: { default: PartyDetails },
      meta: { middleware: auth },
    },
    {
      path: "update-info",
      name: "Update Party Info",
      components: { default: UpdatePartyInfo },
      meta: { middleware: auth },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: { middleware: auth },
    },
    {
      path: "maps",
      name: "Maps",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
        middleware: auth,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: comingSoon },
      meta: { middleware: auth },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
        middleware: auth,
      },
      components: { default: RtlSupport },
    },
  ],
};
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  {
    path: "/",
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: { middleware: auth },
      },
      {
        path: "routing",
        name: "Routing",
        components: { default: Routing },
        meta: { middleware: auth },
      },
      {
        path: "party-dashboard",
        name: "Dashboard",
        components: { default: PartyDashboard },
        meta: { middleware: auth },
      },
    ],
  },
  partyMenu,
  vendorsMenu,
  customersMenu,
  examplesMenu,
  authPages,
  settingsMenu,
  partyPortalMenu,
  partyVendorsMenu,
];

export default routes;
