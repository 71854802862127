<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- 
      rose, blue, green, warning
      assignment, description, perm_identity
     -->
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="margin-right:40%; font-size:13px; color:#007bff !important"
              to="/vendor/invoice-entry"
              ><b>Create Invoice</b>
            </router-link>
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/vendor/invoice"
              ><b>Invoice Report</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <!-- <div class="md-layout"> -->
          <div class="stats div1">
            <b style="color: black;">Unpaid Invoices Count</b>
            <h6 style="color:black;" class="title">
              <b> {{ invoiceReport.unpaid_inv }}</b>
            </h6>
          </div>
          <div class="stats div2">
            <b style="float:right; color: black;">Unpaid Amount</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>
                {{
                  invoiceReport.unpaid_Amt && invoiceReport.unpaid_Amt != 0
                    ? formattedCurrency(invoiceReport.unpaid_Amt)
                    : invoiceReport.unpaid_Amt
                }}</b
              >
            </h6>
          </div>
          <div class="stats div3">
            <b style="float:right; color: black;">Amount Due</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>
                {{
                  invoiceReport.due_amt && invoiceReport.due_amt != 0
                    ? formattedCurrency(invoiceReport.due_amt)
                    : invoiceReport.due_amt
                }}</b
              >
            </h6>
          </div>
          <!-- </div> -->
          <!-- <router-link style="color: black;" to="/vendor/invoice-entry"
              ><b>Amount Due</b></router-link
            > -->
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/vendor/payment"
              ><b>Payment Report</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div4">
            <b style="color: black;">Payment Count</b>
            <h6 style="color:black;" class="title">
              <b> {{ paymentReport.pmt_cnt }}</b>
            </h6>
          </div>
          <div class="stats div5">
            <b style="float:right; color: black;">Payment in last 30 Days</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>
                {{
                  paymentReport.pmt_Amt && paymentReport.pmt_Amt != 0
                    ? formattedCurrency(paymentReport.pmt_Amt)
                    : paymentReport.pmt_Amt
                }}</b
              >
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/vendor/po-details"
              ><b>Open PO Report</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div6">
            <b style="color: black;">PO Count</b>
            <h6 style="color:black;" class="title">
              <b>{{ poReport.po_count }}</b>
            </h6>
          </div>
          <div class="stats div7">
            <b style="float:right; color: black;">PO Total Amount</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{
                poReport.open_amount && poReport.open_amount != 0
                  ? formattedCurrency(poReport.open_amount)
                  : poReport.open_amount
              }}</b>
            </h6>
          </div>
          <div class="stats div8">
            <b style="float:right; color: black;">Need By Date</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{
                poReport.open_amount_need_by_date &&
                poReport.open_amount_need_by_date != 0
                  ? formattedCurrency(poReport.open_amount_need_by_date)
                  : poReport.open_amount_need_by_date
              }}</b>
            </h6>
          </div>
        </template>
        <!-- <template slot="footer">
          <div class="stats">
            <router-link style="color:#007bff;" to="/vendor/po-details"
              ><b>View Report</b></router-link
            >
          </div>
        </template> -->
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              :to="{
                path: '/vendor/invoice-entry',
                query: { params: 'grid' },
              }"
              ><b>Invoice Not Booked</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div9">
            <b style="color: black;">Invoice Count </b>
            <h6 style="color:black;" class="title">
              <b>{{ pendingInvoiceReport.invoice_count }}</b>
            </h6>
          </div>
          <div class="stats div10">
            <b style="float:right; color: black;">Invoice Amount</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{
                pendingInvoiceReport.invoice_amt &&
                pendingInvoiceReport.invoice_amt != 0
                  ? formattedCurrency(pendingInvoiceReport.invoice_amt)
                  : pendingInvoiceReport.invoice_amt
              }}</b>
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="vendor/advance-report"
              ><b>Open Advance</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div11">
            <b style="color: black;">Advance Count</b>
            <h6 style="color:black;" class="title">
              <b>{{ advanceInvoiceReport.advance_count }}</b>
            </h6>
          </div>
          <div class="stats div12">
            <b style="float:right; color: black;">Advance Amount</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{
                advanceInvoiceReport.advance_amt &&
                advanceInvoiceReport.advance_amt != 0
                  ? formattedCurrency(advanceInvoiceReport.advance_amt)
                  : advanceInvoiceReport.advance_amt
              }}</b>
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33">
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/vendor/party-onboarding"
              ><b>Vendor Onboarding</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div13">
            <b style="float:right; color: black;">Vendor Onboarding Status</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{ vendorApprovalStatus }}</b>
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33"
      v-if="vendorAdmin !== 'PARTY_REQ_ADMIN'"
    >
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              :to="{
                path: '/vendor/rfp',
              }"
              ><b>RFP Details</b>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div14">
            <b style="color: black;">Pending for Bidding</b>
            <h6 style="color:black;" class="title">
              <b>{{  pendingBidCount }}</b>
            </h6>
          </div>
          <div class="stats div10">
            <b style="float:right; color: black;">Open RFP</b>
            <h6 style="color:black;" class="title text-align-fix">
              <b>{{ openRfpCount  }}</b>
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <div v-if="flagAdmin"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-33"
    >
      <stats-card class="border stats-height" header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <div style="margin-top:3%;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              :to="{
                path: '/vendor/user-management',
              }"
              ><b>User Management</b>
               <h6 style="color:black;" class="title">
              <b>{{ advanceInvoiceReport.advance_count }}</b>
            </h6>
            </router-link>
          </div>
        </template>
        <template slot="footer">
          <div class="stats div14">
            <b style="color: black;">User Count</b>
            <h6 style="color:black;" class="title">
              <b>{{  userCount }}</b>
            </h6>
          </div>
        </template>
      </stats-card>
    </div>
    <!-- start -->
    <!-- 
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('viewProfile')">
            <md-icon>summarize</md-icon>
          </div>
          <p style="margin-top:3%; color: black;" class="category">
            <b>Invoice Report</b>
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link style="color:#007bff;" to="/vendor/invoice"
              ><b>View Report</b></router-link
            >
          </div>
        </template>
        <template slot="footer">
          <div style="margin-left:45%" class="stats">
            <router-link style="color:#007bff;" to="/vendor/invoice-entry"
              ><b>Create Invoice</b></router-link
            >
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('vendor')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vendor O/S</p>
          <h6 class="title">{{ vendorCount }}</h6>
        </template>

        <template slot="footer">
          <div class="stats" @click="navigateFromVendorOs()">
            <router-link to="/vendor/invoice">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('customer')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Customer O/S</p>
          <h6 class="title">{{ customerCount.customer_os }}</h6>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/customer/dashboard">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      @click="selectedTab('notification')"
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>notifications</md-icon>
          </div>
          <p class="category">Notification</p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/party/notifications">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>

    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('viewProfile')">
            <md-icon>summarize</md-icon>
          </div>
          <p style="margin-top:3%; color: black;" class="category">
            <b>Payment Report</b>
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link style="color:#007bff;" to="/vendor/payment"
              ><b>View Report</b></router-link
            >
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('vendor')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vendor O/S</p>
          <h6 class="title">{{ vendorCount }}</h6>
        </template>

        <template slot="footer">
          <div class="stats" @click="navigateFromVendorOs()">
            <router-link to="/vendor/invoice">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('customer')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Customer O/S</p>
          <h6 class="title">{{ customerCount.customer_os }}</h6>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/customer/dashboard">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      @click="selectedTab('notification')"
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>notifications</md-icon>
          </div>
          <p class="category">Notification</p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/party/notifications">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>

    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('viewProfile')">
            <md-icon>summarize</md-icon>
          </div>
          <p style="margin-top:3%; color: black;" class="category">
            <b>PO Report</b>
          </p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link style="color:#007bff;" to="/vendor/po-details"
              ><b>View Report</b></router-link
            >
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('vendor')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vendor O/S</p>
          <h6 class="title">{{ vendorCount }}</h6>
        </template>

        <template slot="footer">
          <div class="stats" @click="navigateFromVendorOs()">
            <router-link to="/vendor/invoice">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('customer')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Customer O/S</p>
          <h6 class="title">{{ customerCount.customer_os }}</h6>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/customer/dashboard">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      style="margin-top:2rem;"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
    >
      @click="selectedTab('notification')"
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>notifications</md-icon>
          </div>
          <p class="category">Notification</p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/party/notifications">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>

     -->
    <!-- close -->

    <!-- <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'viewProfile'"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
          style="margin-top: 8px"
        >
          <div class="card-text profile-card" style="padding: 5px !important">
            <h5 class="title">Profile</h5>
            <p class="category">Last updated on {{ lastUpdateDate }}</p>
          </div>
        </md-card-header>
        <DetailsComponent
          partyDetailsVisible="false"
          @getDetails="getDetails"
        />
      </md-card>
    </div> -->

    <!-- <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'vendor'"
      style="margin-top: 10px"
    >
      <chart-card
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        :chart-responsive-options="emailsSubscriptionChart.responsiveOptions"
        chart-type="Line"
        chart-inside-header
        background-color="blue"
      >
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated 10 days ago
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'customer'"
      style="margin-top: 10px"
    >
      <chart-card
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        chart-type="Line"
        chart-inside-header
        background-color="green"
      >
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated 4 minutes ago
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'notification'"
    >
      <md-card>
        <md-card-header>
          <h6 class="title">Notifications Style</h6>
        </md-card-header>
        <md-card-content style="padding: 5px 10px !important">
          <div class="alert alert-info">
            <span>This is a plain notification</span>
          </div>
          <div class="alert alert-info">
            <button type="button" aria-hidden="true" class="close">×</button>
            <span>This is a notification with close button.</span>
          </div>
          <div class="alert alert-info alert-with-icon" data-notify="container">
            <button type="button" aria-hidden="true" class="close">×</button>
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message"
              >This is a notification with close button and icon and have many
              lines. You can see that the icon and the close button are always
              vertically aligned. This is a beautiful notification. So you don't
              have to worry about the style.</span
            >
          </div>
          <div class="alert alert-rose alert-with-icon" data-notify="container">
            <button type="button" aria-hidden="true" class="close">×</button>
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message"
              >This is a notification with close button and icon and is made
              with ".alert-rose". You can see that the icon and the close button
              are always vertically aligned. This is a beautiful notification.
              So you don't have to worry about the style.</span
            >
          </div>
        </md-card-content>
      </md-card>
    </div> -->
  </div>
</template>

<script>
import { StatsCard } from "@/components";
import commonHelper from "../../utility/common.helper.utility";
// import { DetailsComponent } from "@/pages/Dashboard/Party/PartyInfo";
export default {
  components: {
    StatsCard,
    // ChartCard,
    // DetailsComponent,
  },
  data() {
    return {
      unsubscribe: null,
      tabValue: "viewProfile",
      seq2: 0,
      leId: null,
      loader: false,
      finYear: null,
      currentFinYear: null,
      lastUpdateDate: "",
      selected: [],
      vendorCount: 0,
      customerCount: 0,
      vendorId: null,
      emailsSubscriptionChart: {
        data: {
          labels: ["0-30", "31-60", "61-180", "> 180"],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      invoiceReport: {},
      paymentReport: {},
      poReport: {},
      pendingInvoiceReport: {},
      advanceInvoiceReport: {},
      vendorApprovalStatus: null,
      vendorAdmin: null,
      pendingBidCount: 0,
      openRfpCount: 0,
      userId: null,
      userCount: null,
      flagAdmin:null,
    };
  },
  mounted() {
    this.flagAdmin=JSON.parse(sessionStorage.getItem("setUserAdminFlag"))
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    this.getName();
    this.vendorAdmin = sessionStorage.getItem("vendorPortalAdmin");
    this.getRfpOpenCloseData();
    // if (!this.vendorId) {
    //   this.getName();
    // }
    // if (this.vendorId) {
    //   this.getInvoiceReportCount();
    //   this.getPaymentReportCount();
    //   this.getPoReportCount();
    // }

    // this.leId = sessionStorage.getItem("leId")
    //   ? sessionStorage.getItem("leId")
    //   : "All";
    // this.finYear = sessionStorage.getItem("finYear")
    //   ? sessionStorage.getItem("finYear")
    //   : "All";
    // this.currentFinYear = sessionStorage.getItem("currentFinYear")
    //   ? sessionStorage.getItem("currentFinYear")
    //   : null;
    // this.getVendorCount();
    // // this.getCustomerCount();
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (
    //     mutation.type === "shared/setLeId" ||
    //     mutation.type === "shared/setFinYear"
    //   ) {
    //     this.leId = state.shared.leId;
    //     this.finYear = state.shared.finYear;
    //     this.getVendorCount();
    //     this.getCustomerCount();
    //   }
    //   if (mutation.type === "shared/setCurrentFinYear") {
    //     this.currentFinYear = state.shared.currentFinYear;
    //     this.getCustomerCount();
    //   }
    // });
  },
  methods: {
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorApprovalStatus = result.approval_status;
            this.$store.commit("vendor/setVendorId", result.ora_party_id);
            sessionStorage.setItem(
              "VendorId",
              JSON.stringify(result.ora_party_id)
            );
            this.eventBus.$emit("vendorName", result.name);
            this.$store.commit("vendor/setVendorName", result.name);
            sessionStorage.setItem("VendorName", JSON.stringify(result.name));
            this.vendorId = result.ora_party_id;
               if(this.flagAdmin){
                 this.getUserCount(this.vendorId)
                 }
            if (this.vendorId) {
              this.getInvoiceReportCount();
              this.getPaymentReportCount();
              this.getPoReportCount();
              this.getPendingReportCount();
              this.getAdvanceReportCount();
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    formattedCurrency(num) {
      return commonHelper.formatAmountWithCurrency(Number(num));
    },
    getInvoiceReportCount() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getInvoiceReportCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result) {
              this.invoiceReport = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPaymentReportCount() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getPaymentReportCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result) {
              this.paymentReport = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPoReportCount() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getPoReportCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result) {
              this.poReport = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPendingReportCount() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getPendingReportCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result) {
              this.pendingInvoiceReport = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAdvanceReportCount() {
      const payload = {
        vendor_id: this.vendorId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getAdvanceReportCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result) {
              this.advanceInvoiceReport = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    //
    getVendorCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        // fin_year: this.finYear !== "All" ? this.finYear : null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getVendorCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.vendorCount = response.data.data;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getCustomerCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear, // not null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getCustomerCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.customerCount = response.data.data;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function(items) {
      this.selected = items;
    },
    getDetails(item) {
      this.lastUpdateDate = item.latest_lud;
    },
    navigateFromVendorOs() {
      this.$store.dispatch("shared/setVendorOsFlag", true);
    },
    getRfpOpenCloseData() {
      const payload = {
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getRfpOpenCloseData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.pendingBidCount = result.pending_for_bidding;
            this.openRfpCount = result.open_rfp;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUserCount(id) {
      const payload = {
        oraPartyId: id,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getUsersCount", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.userCount=result
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    //
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // },
};
</script>
<style>
.div1 {
  max-width: 33% !important;
  margin-right: 11% !important;
}
.div2 {
  max-width: 33% !important;
  margin-right: 10% !important;
}
.div3 {
  max-width: 33% !important;
}
.div4 {
  max-width: 50% !important;
  margin-right: 45% !important;
}
.div5 {
  max-width: 50% !important;
  /* text-align: right !important; */
}
.div6 {
  max-width: 33% !important;
  margin-right: 20% !important;
}
.div7 {
  max-width: 33% !important;
  margin-right: 20% !important;
}
.div8 {
  max-width: 33% !important;
}
.div9 {
  max-width: 50% !important;
  margin-right: 58% !important;
}
.div10 {
  max-width: 50% !important;
}
.div11 {
  max-width: 50% !important;
  margin-right: 55% !important;
}
.div12 {
  max-width: 50% !important;
}
.div13 {
  max-width: 100% !important;
  margin-left: 63% !important;
  /* text-align: end !important; */
}
.div14 {
  max-width: 50% !important;
  margin-right: 57% !important;
}
.div15 {
  max-width: 50% !important;
  margin-right: 57% !important;
}
.header-report {
  font-size: 13px !important;
  color: #007bff !important;
}
.border:hover {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.text-align-fix {
  text-align: right !important;
}
.stats-height {
  height: 88% !important;
}
</style>
