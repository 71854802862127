<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <!-- <b-form-select v-model="selected" :options="options"></b-form-select> -->
      <md-button
        class="md-raised md-primary search-btn"
        @click="downloadExcelData()"
        >Download Excel</md-button
      >
    </div>
    <div style="margin-top: 20px">
      <b-table
        striped
        hover
        bordered
        outlined
        sticky-header
        :items="rpfLineBoqData"
        :fields="rpfLineBoqField"
        show-empty
        class="bg-white"
      >
      </b-table>
    </div>
    <div
      class="md-layout-item md-small-size-100 md-size-10"
      style="margin-left: -15px;"
    >
      <md-button class="md-raised md-primary search-btn" @click="getUploadedDocDtl()"
        >Submit</md-button
      >
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  name: "RFPDetailsReview",
  props: ["rfpHdrDetails", "bidHeaderId"],
  data() {
    return {
      rpfLineBoqData: [],
      rpfLineBoqField: [
        {
          key: "boq_line_CODE",
          label: "BOQ Line Code",
        },
        {
          key: "boq_description",
          label: "Description",
        },
        {
          key: "location_name",
        },
        {
          key: "cost_centre",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "unit_price",
        },
        {
          key: "remarks",
        },
        {
          key: "attributes",
        },
      ],
      selected: null,
      options: [
        { value: null, text: "Version 1" },
        { value: 2, text: "Version 2" },
        { value: 3, text: "Version 3" },
        { value: 4, text: "Version 4" },
      ],
      menuSubmenuBidHeader: {
        menuId: null,
        submenuId: null,
      }
    };
  },
  mounted() {
    this.setMenuSubmenuId();
    if (this.rfpHdrDetails) {
      this.getRfpLineBoqData(this.rfpHdrDetails);
    }
  },
  methods: {
    getRfpLineBoqData(data) {
      const payload = {
        rfp_header_id: data.rfp_header_id,
        bid_hdr_id: this.bidHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/reviewBoqBid", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.rpfLineBoqData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    submitBid() {
      const payload = {
        bid_hdr_id: this.bidHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postRfpBoqBidSubmit", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.rfpHdrDetails.bidStatus = "SUBMIT";
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "success");
          } else {
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, "danger");
          }
        })
        .catch((err) => {
          this.loader = false;
          this.makeToast(err.message, "danger");
        });
    },
    downloadExcelData() {
      let data = [];
      for (let i of this.rpfLineBoqData) {
        data.push({
          location_name: i.location_name,
          boq_line_CODE: i.boq_line_CODE,
          boq_description: i.boq_description,
          quantity: i.quantity,
          unit_price: i.unit_price,
          remarks: i.remarks,
          attributes: i.attributes,
        });
      }
      this.makeToast("Excel Sheet downloading!", "success");
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Review");
      XLSX.writeFile(wb, "review.xlsx");
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-center",
        appendToast: true,
        toastClass: "custom-toast",
      });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: 0,
        _limit: 10,
        menu_id: this.menuSubmenuBidHeader.menuId,
        sub_menu_id: this.menuSubmenuBidHeader.submenuId,
        record_id: this.bidHeaderId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            let result =  response.data.data.page;
            if(result.length === 0) {
              alert('Please upload document first')
            } else {
              this.submitBid()
            }
            
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    setMenuSubmenuId() {
      const menuBidHeader = JSON.parse(sessionStorage.getItem("rfpBidHdr"));
      this.menuSubmenuBidHeader = {
        menuId: menuBidHeader.menu_id,
        submenuId: menuBidHeader.sub_menu_id,
      };
    },
  },
};
</script>

<style>
.col-large {
  max-width: 10rem !important;
}
.custom-toast {
  padding: 20px;
}
</style>
