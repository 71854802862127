import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
};

const mutations = {};

const actions = {
  getVendorCount(context, payload) {
    return http.getApi(URL_UTILITY.getVendorCountUrl, payload);
  },
  getCustomerCount(context, payload) {
    return http.getApi(URL_UTILITY.getCustomerCountUrl, payload);
  },
  getVendorSecurityCount(context, payload) {
    return http.getApi(URL_UTILITY.getVendorSecurityUrl +'-deposit', payload);
},
  getInvoiceReportCount(context, payload) {
    return http.postApi(URL_UTILITY.getInvoiceReportCountUrl, payload);
  },
  getPaymentReportCount(context, payload) {
    return http.postApi(URL_UTILITY.getPaymentReportCountUrl, payload);
  },
  getPoReportCount(context, payload) {
    return http.postApi(URL_UTILITY.getPoReportCountUrl, payload);
  },
  getPendingReportCount(context, payload) {
    return http.getApi(URL_UTILITY.getPendingReportCountUrl, payload);
  },
  getAdvanceReportCount(context, payload) {
    return http.postApi(URL_UTILITY.getAdvanceReportCountUrl, payload);
  }, 
  getRecoveryInvoiceCount(context,payload){
    return http.getApi(URL_UTILITY.getRecoveryInvoiceUrl, payload )
  },
  getRfpOpenCloseData(context, payload) {
    return http.getApi(URL_UTILITY.getRfpDetails + `/dashboard-rfp`, payload);
  },
  getPmcPaidUptoUrl(context ,payload){
    return http.getApi(URL_UTILITY.getPmcPaidUptoUrl , payload)
  },
  getUsersCount(context ,payload){
    return http.getApi(URL_UTILITY.getVendorPortalExternal + `/vendor-user-manual/active-count/${payload.oraPartyId}`)
  },
};

const getters = {};

const dashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dashboard;
