<template>
  <div style="margin-left: 500px !important">
    <md-card class="md-layout-item md-size-40" style="height: auto">
      <b-alert
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <md-card-content>
        <h3 class="text-center">
          <b><u>Change Password</u></b>
        </h3>
        <div style="margin: auto; padding: 20px">
          <div class="form-group px-24">
            <label for="password">Current Password</label>
            <div>
              <!-- <v-otp-input
                ref="password"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnComplete"
              /> -->
              <md-field class="form-group md-invalid" slot="inputs">
                <!-- <md-icon style="float: left">lock_outline</md-icon> -->
                <!-- <label>Password</label> -->
                <md-input
                  v-model="currentPassword"
                  type="password"
                  placeholder="Current Password"
                  style="width: 169%;"
                />
              </md-field>
            </div>
          </div>
          <div class="form-group px-24" style="margin-top: 1rem">
            <label for="password">New Password</label>
            <div>
              <!-- <v-otp-input
                ref="password"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnChange"
              /> -->
              <md-field class="form-group md-invalid" slot="inputs">
                <!-- <md-icon style="float: left">lock_outline</md-icon> -->
                <!-- <label>Password</label> -->
                <md-input
                  v-model="newPassword"
                  type="password"
                  placeholder="New Password"
                  style="width: 169%;"
                />
              </md-field>
            </div>
          </div>
          <div class="form-group px-24" style="margin-top: 1rem">
            <label for="password">Verify Password</label>
            <div>
              <!-- <v-otp-input
                ref="verifyPassword"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-complete="handleOnCompleteSetPassword"
              /> -->
              <md-field class="form-group md-invalid" slot="inputs">
                <!-- <md-icon style="float: left">lock_outline</md-icon> -->
                <!-- <label>Password</label> -->
                <md-input
                  v-model="verifyNewPassword"
                  type="password"
                  placeholder="Verify Password"
                  style="width: 169%;"
                />
              </md-field>
              <div>
                <md-button
                  class="md-rose"
                  style="width: 100%; margin-top: 10px;"
                  @click="handleOnCompleteSetPassword"
                  >Submit</md-button
                >
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import appStrings from "../../../utility/string.utility";
export default {
  // components: {ValidationError},
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      currentPassword: "",
      newPassword: "",
      verifyNewPassword: "",
    };
  },
  mounted() {
      window.addEventListener("popstate", this.handlePopState);
  },
  beforeUnmount() {
      window.removeEventListener("popstate", this.handlePopState);
  },
  methods: {
    handleOnComplete: function(value) {
      this.currentPassword = value;
    },
    handleOnChange: function(value) {
      this.newPassword = value;
      this.error = {
        setPassword: "",
      };
      this.reset = false;
    },
    handleOnCompleteSetPassword: function() {
      if (this.verifyNewPassword != this.newPassword) {
        this.error = {
          setPassword: "Password not match",
        };
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = this.error.setPassword;
      } else {
        this.changePassword();
      }
    },
    changePassword() {
      if (this.currentPassword === "") {
        alert("Please enter current password");
      } else if (this.newPassword === "") {
        alert("Please enter New Password");
      } else if (this.verifyNewPassword === "") {
        alert("Please verify New Password");
      } else if (
        this.currentPassword === "" &&
        this.newPassword === "" &&
        this.verifyNewPassword === ""
      ) {
        alert("All fields required!");
      } else {
        if (this.currentPassword !== this.newPassword) {
          const payload = {
            old_password: this.encryptResponse(this.currentPassword),
            new_password: this.encryptResponse(this.newPassword),
          };
          this.$store.dispatch("shared/setLoader", true);
          this.$store
            .dispatch("party/changePassword", payload)
            .then((response) => {
              this.$store.dispatch("shared/setLoader", false);
              this.showAlert = true;
              if (response.status === 200) {
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                alert("Password Changed Successfully");
                this.$store.dispatch("logout");
              } else {
                this.isSuccess = false;
                this.responseMsg = response.data.message;
              }
            })
            .catch(() => {
              this.$store.dispatch("shared/setLoader", false);
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        } else {
          alert("New password cannot be the same as your old password.");
        }
      }
    },
    handlePopState() {
      if(sessionStorage.getItem("isNewVendor") || (sessionStorage.getItem("resetPassword") === 'Y')) {
        this.$store.dispatch("logout");
      }
    },
    encryptResponse(payload) {
      const key = "QUtDTUVGWEhKTEtMTVlaUA==";
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);
      const encryptedData = CryptoJS.AES.encrypt(payload, keyForCryptoJS, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encryptedData.toString();
    },
  },
};
</script>
