<template>
  <div class="md-layout text-center login-fix-page">
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- <div style="margin-left: 127%;">
      <md-button
        :style="
          showLoginForm ? 'margin-top: -10.2rem;' : 'margin-top: -17.5rem;'
        "
        class="md-rose"
        @click="showHideLoginFrom()"
        >Login</md-button
      >
    </div> -->
    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
      style="display: flex; justify-content: center"
    >
      <!-- style="display: flex; justify-content: center; margin-left: 77rem; margin-top: -8rem" -->
      <!-- <form @submit.prevent="login" v-if="showLoginForm"> -->
      <form @submit.prevent="login">
        <login-card header-color="rose">
          <h4 slot="title" class="title">Log in to access your Dashboard</h4>
          <md-field
            v-if="
              status != 'setPassword' ||
                (status === 'success' && status != 'success')
            "
            class="form-group md-invalid"
            slot="inputs"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input
              v-model="email"
              type="email"
              @blur="validateEmail"
              :readonly="showTimer"
              v-on:focus="
                error = {
                  message: '',
                }
              "
            />
            <div
              v-if="error.reset"
              class="error"
              role="alert"
              style="position: absolute;
              margin-top: 2.5rem;"
            >
              {{ error.reset }}
            </div>
            <validation-error v-else :errors="apiValidationErrors.email" />
          </md-field>
          <div
            class="form-group md-invalid"
            style="display: flex; flex-direction: column;"
            slot="inputs"
          >
            <div
              v-if="status === 'login' || status === 'success'"
              :class="{ disabledPass: showTimer }"
            >
              <!-- <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              /> -->
              <md-field
                class="form-group md-invalid"
                slot="inputs"
                v-if="showTimerForgot"
              >
                <md-icon style="float: left">lock_outline</md-icon>
                <label>Password</label>
                <md-input
                  v-model="password"
                  type="password"
                  style="width: 169%;"
                />
              </md-field>
            </div>
            <div class="form-group" v-if="showTimer">
              <div class="timer">
                <div v-if="showTimerText">
                  <p>
                    OTP expires in: {{ formatTime(timerMinutes) }}:{{
                      formatTime(timerSeconds)
                    }}
                    minutes
                  </p>
                </div>
              </div>
              <div style="display: flex; flex-direction: row" class="mt-1">
                <v-otp-input
                  ref="verifyOtp"
                  input-classes="otp-input"
                  separator="-"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  input-disabled="true"
                  input-type="password"
                  @on-change="handleOnVerify2FAOTP"
                  @on-complete="handleOnComplete2FAOTP"
                />
              </div>

              <div class="alert alert-danger" v-if="error.verify">
                <p>OTP is incorrect</p>
              </div>
            </div>
            <div v-if="status === 'login' || status === 'success'">
              <md-button class="md-rose  " v-if="!showTimer" @click="login()"
                >Login</md-button
              >
            </div>
            <div class="form-group px-24" v-if="status === 'verify'">
              <!-- <label for="password">OTP</label> -->
              <div class="timer">
                <label for="password">OTP</label>
                <div v-if="showTimerForgotText">
                  <p style="margin-left: 20px;">
                    OTP expires in: {{ formatTime(forgotMinutes) }}:{{
                      formatTime(forgotSeconds)
                    }}
                    minutes
                  </p>
                </div>
              </div>
              <div style="display: flex; flex-direction: row" class="mt-1">
                <v-otp-input
                  ref="verifyOtp"
                  input-classes="otp-input"
                  separator="-"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  input-disabled="true"
                  input-type="password"
                  @on-change="handleOnVerifyOTP"
                  @on-complete="handleOnCompleteOTP"
                />
              </div>

              <div class="alert alert-danger" v-if="error.verify">
                <p>OTP is incorrect</p>
              </div>
            </div>
            <div v-if="status === 'setPassword'">
              <div class="form-group px-24">
                <div v-if="error.newPassword">
                  <div class="alert alert-danger">
                    {{ error.newPassword }}
                  </div>
                </div>
              </div>

              <div class="form-group px-24">
                <label for="password">New Password</label>
                <div style="display: flex; flex-direction: row" class="mt-1">
                  <!-- <v-otp-input
                    ref="password"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    input-disabled="true"
                    input-type="password"
                    @on-change="handleOnChange"
                  /> -->
                  <md-field class="form-group md-invalid" slot="inputs">
                    <md-input
                      v-model="newPass"
                      type="password"
                      placeholder="New Password"
                      style="width: 169%;"
                    />
                  </md-field>
                </div>
              </div>
              <div class="form-group px-24">
                <label for="password">Verify Password</label>
                <div style="display: flex; flex-direction: row" class="mt-1">
                  <!-- <v-otp-input
                    ref="verifyPassword"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="6"
                    :is-input-num="true"
                    input-disabled="true"
                    input-type="password"
                    @on-complete="handleOnCompleteSetPassword"
                  /> -->
                  <md-field class="form-group md-invalid" slot="inputs">
                    <md-input
                      v-model="verifyPassword"
                      type="password"
                      placeholder="Verify Password"
                      style="width: 169%;"
                    />
                  </md-field>
                </div>
              </div>
              <div>
                <md-button class="md-rose" @click="setNewChangePass"
                  >Submit</md-button
                >
              </div>
            </div>
            <p
              v-if="
                status != 'verify' &&
                  status != 'success' &&
                  status != 'setPassword'
              "
            >
              <span
                v-if="
                  status != 'verify' &&
                    status != 'success' &&
                    status != 'setPassword' &&
                    !showTimer
                "
                class="pointer"
                style="color: deeppink"
                href="#"
                @click="resetPassword"
                :class="isValid ? 'btn' : 'btn disabled'"
                >Forgot Password</span
              >
            </p>
            <div
              :class="
                this.showTimerText || this.showTimerForgotText
                  ? 'disabled-resend-otp'
                  : 'forgot-password'
              "
            >
              <p
                v-if="showTimer || !showTimerForgot"
                style="margin-top: -5px; font-size: 14px; text-align:center; text-decoration: underline;"
                @click="sendOtpType()"
              >
                Resend OTP
              </p>
            </div>
            <div class="news-ticker">
              <div class="ticker-wrapper">
                <span class="ticker-text"
                  >Updated password policy: Minimum 8 characters, including
                  uppercase, lowercase, numbers, and special characters for
                  enhanced security.</span
                >
                <span class="ticker-text"
                  >Updated password policy: Minimum 8 characters, including
                  uppercase, lowercase, numbers, and special characters for
                  enhanced security.</span
                >
              </div>
            </div>
          </div>
        </login-card>
      </form>
    </div>
    <b-modal
      v-model="consentModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="lg"
      title="Consent"
      @hidden="openConsentModal(false)"
    >
      <p class="term-text">{{ consentData }}</p>
      <div style="display: flex" v-if="!isDocumentUploaded">
        <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
          <md-field md-clearable>
            <label>Document</label>
            <md-file required v-model="single" @md-change="selectFile" />
          </md-field>
        </div>
        <div>
          <md-button
            style="margin-top:20px; margin-left:15px;"
            class="md-raised md-primary search-btn"
            v-if="single"
            @click="getOpenKmAuthenticate()"
            >Upload Document</md-button
          >
        </div>
      </div>
      <div v-if="isDocumentUploaded" class="md-layout">
        <div style="margin-top:2.5rem;" class="md-layout-item md-size-50">
          <b>Document Name: </b>
          <span style="margin-top:1.8rem; cursor:pointer;">
            <a
              style="margin-left:0.5rem;"
              target="_blank"
              :href="
                downloadDocUrl +
                  '/download/' +
                  docDetailId +
                  '?token=' +
                  openKmAuth
              "
              ><b>{{ fileName }}</b></a
            >
          </span>
        </div>
        <div style="margin-top:2rem;" class="md-layout-item md-size-50">
          <b style="margin-left:1rem;">Delete Document: </b>
          <span
            class="material-icons"
            style="cursor:pointer; margin-left:2rem;"
            @click="deleteDocument(docDetailId, openKmAuth)"
            >delete</span
          >
        </div>
      </div>
      <md-button
        class="md-raised md-rose"
        style="margin-left: 20px"
        @click="addEditTermsCondition()"
        :disabled="!isDocumentUploaded"
        >Accept</md-button
      >
    </b-modal>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import { validateEmail } from "../../../services/api";
import formMixin from "@/mixins/form-mixin";
import URL_UTILITY from "../../../utility/url.utility";
import CryptoJS from "crypto-js";

export default {
  components: {
    LoginCard,
    ValidationError,
  },

  mixins: [formMixin],

  data: () => ({
    loader: false,
    email: "",
    password: null,
    error: {},
    isValid: false,
    status: "login",
    reset: false,
    otp: " ",
    message: " ",
    verifyPassword: null,
    // showLoginForm: false,
    loginResponse: {
      token: null,
      user_ref_id: null,
      party_type: null,
      user_type: null,
      is_new_vendor: null,
    },
    showPassword: false,
    showTimer: false,
    status: "login",
    storeTime: null,
    isPasswordExpired: false,
    title: "Login",
    showAlert: false,
    isSuccess: false,
    responseMsg: "",
    otpType: null,
    showTimerText: false,
    timerInterval: null,
    token2FA: null,
    otp2FA: null,
    gmailAuthFlag: false,
    resend2FAOTPFlag: false,
    forgotSeconds: 0,
    forgotMinutes: 0,
    showTimerForgotText: false,
    showTimerForgot: true,
    timerIntervalForgot: null,
    storeForgotTime: null,
    newPass: null,
    showNewPassword: false,
    showVerifyPassword: false,
    // downloadGstUserManualUrl: URL_UTILITY.getGstManualDocumentUrl,
    // userManualGstFile: appStrings.GSTUSERMANUALFILENAME,
    encrypteForgotRes: null,
    userName: null,
    userDtl: null,
    notificationCount: 0,
    timerSeconds: 0,
    timerMinutes: 0,
    response: null,
    consentModal: false,
    consentData: null,
    termId: null,
    partyReqId: null,
    single: null,
    fileRef: null,
    openKmAuth: null,
    isDocumentUploaded: false,
    docDetailId: null,
    fileName: null,
    downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
    menuId: 374,
    submenuId: 88,
    loginToken: null,
  }),
  mounted() {
    this.partyReqId = this.$store.state.auth.partyId;
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    let vendorToken = sessionStorage.getItem("encryptedToken");
    let secretKey = "CON_DSL_123";
    sessionStorage.clear();
    if (vendorToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(vendorToken, secretKey);
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        this.loginToken = decryptedToken;
        if (this.loginToken) {
          this.login();
        }
        this.loginToken = null;
      } catch (error) {
        this.$bvToast.toast("Error decrypting token:", error, {
          title: "Alert",
          variant: "danger",
          solid: true,
        });
      }
    } else {
      this.$bvToast.toast("No token found in the URL", {
        title: "Alert",
        variant: "danger",
        solid: true,
      });
    }
  },
  methods: {
    validateEmail() {
      const email = this.email;
      const isEmailValid = validateEmail(email);
      isEmailValid
        .then((resp) => {
          if (resp.status == 200) this.isValid = true;
          else {
            this.$store.dispatch("alerts/error", resp.data.message);
            this.error = {
              message: "User not found",
              user: null,
            };
          }
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", resp.data.message);
          this.error = {
            message: "User not found",
            user: err,
          };
        });
    },
    handleOnComplete: function(value) {
      this.password = value;
      this.login();
    },
    handleOnChange: function(value) {
      this.verifyPassword = value;
      this.error = {
        setPassword: "",
      };
      this.reset = false;
    },
    handleOnCompleteOTP: function(value) {
      this.otp = value;
      this.forgotPassword();
    },
    handleOnVerifyOTP: function(value) {
      this.error = {
        verify: "",
      };
      this.otp = value;
    },
    handleOnCompleteSetPassword: function(value) {
      if (this.newPass != this.verifyPassword) {
        this.error = {
          setPassword: "Password not match",
        };
        this.showAlert = true;
        this.isSuccess = false;
        this.isPasswordExpired = false;
        clearInterval(this.timerIntervalForgot);
        this.newPass = null;
        this.verifyPass = null;
        this.gmailAuthFlag = false;
        this.$store.dispatch("alerts/error", "Password not match");
      } else {
        this.setPassword();
      }
    },
    async login() {
      if (this.loginToken !== null) {
        var payload = {
          // user_email: this.email,
          // password: this.password,
          token: this.loginToken,
        };
        this.loginToken = null;
      } else {
        var payload = {
          user_email: this.email,
          password: this.encryptResponse(this.password),
        };
      }

      this.loader = true;
      this.$store
        .dispatch("login", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.$store.dispatch("alerts/error", response.data.message);
            this.loginResponse = response.data;
            sessionStorage.setItem(
              "setUserAdminFlag",
              response.data.external_admin
            );
            this.response = response;
            const token = response.data.token;
            const encryptedData = response.data.token2fa;
            this.partyReqId = response.data.user_ref_id;
            const decryptData = JSON.parse(this.decryptResponse(encryptedData));
            const is2faEnable = decryptData.is2faEnable;
            this.storeTime = +decryptData.time2fa;
            sessionStorage.setItem(
              "bgvcConsentFlag",
              response.data.bgvc_consent_flag
            );
            if (decryptData.isPasswordExpired) {
              this.isPasswordExpired = true;
              this.status = "setPassword";
              this.title = "Change Password";
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg =
                "Your password is expired please change your password";
            } else {
              if (token && !is2faEnable) {
                sessionStorage.setItem("authToken", response.data.token);
                this.$store.dispatch("setAuthToken", response.data.token);
                if (
                  this.loginResponse.vendor_portal_admin == "PARTY_REQ_ADMIN"
                ) {
                  sessionStorage.setItem("userType", response.data.user_type);
                  this.$store.dispatch("setUserType", response.data.user_type);
                  sessionStorage.setItem("userId", response.data.user_id);
                  this.$store.dispatch("setUserId", response.data.user_id);
                  sessionStorage.setItem(
                    "vendorPortalAdmin",
                    response.data.vendor_portal_admin
                  );
                  this.$store.dispatch(
                    "setVendorPortalAdmin",
                    response.data.vendor_portal_admin
                  );
                  if (response.data.resetPassword === "Y") {
                    sessionStorage.setItem(
                      "resetPassword",
                      response.data.resetPassword
                    );
                    this.$router.push({ name: "Change Password" });
                  } else {
                    this.$router.push({ name: "Admin Page" });
                  }
                } else {
                  // if (
                  //   !response.data.is_new_vendor &&
                  //   response.data.bgvc_consent_flag
                  // ) {
                  //   this.userId = response.data.user_id;
                  //   this.getUploadedDoc();
                  //   this.acceptConsent();
                  //   this.consentModal = true;
                  // } else {
                  sessionStorage.setItem("partyId", response.data.user_ref_id);
                  this.$store.dispatch("setPartyId", response.data.user_ref_id);
                  sessionStorage.setItem("partyType", response.data.party_type);
                  this.$store.dispatch(
                    "setPartyType",
                    response.data.party_type
                  );
                  sessionStorage.setItem(
                    "isNewVendor",
                    response.data.is_new_vendor
                  );
                  this.$store.dispatch(
                    "setIsNewVendor",
                    response.data.is_new_vendor
                  );
                  sessionStorage.setItem("userType", response.data.user_type);
                  this.$store.dispatch("setUserType", response.data.user_type);
                  sessionStorage.setItem("userId", response.data.user_id);
                  this.$store.dispatch("setUserId", response.data.user_id);
                  sessionStorage.setItem(
                    "vendorPortalAdmin",
                    response.data.vendor_portal_admin
                  );
                  this.$store.dispatch(
                    "setVendorPortalAdmin",
                    response.data.vendor_portal_admin
                  );
                  if (response.data.user_type == "PARTY") {
                    this.$router.push({ path: "/party-dashboard" });
                  } else if (response.data.user_type == "VENDOR") {
                    // this.$router.push({ name: "Home" });
                    this.getName();
                  } else if (response.data.user_type == "BOTH") {
                    sessionStorage.setItem("bothTypeUser", "BOTH");
                    this.$router.push({ name: "Routing" });
                  }
                  // }
                }
                this.getMenuSubMenuId();
              } else if (is2faEnable) {
                this.token2FA = token;
                this.timerMinutes = response.data.time2fa;
                this.startTimer("sendOtp");
              }
            }
          } else {
            this.$store.dispatch("alerts/error", response.data.message);
            this.$refs.otpInput?.clearInput();
            // this.setApiValidation(response.data.errors);
          }
        })
        .catch((err) => {
          this.loader = false;
          this.error = {
            message: err,
          };
          setTimeout(() => {
            this.error = {
              message: "",
            };
          }, 5000);
          this.password = null;
          this.$refs.otpInput?.clearInput();
        });
    },
    // showHideLoginFrom() { open for login button form
    //   this.showLoginForm = !this.showLoginForm;
    // },
    decryptResponse(encryptedData) {
      const key = "QUtDTUVGWEhKTEtMTVlaUA==";
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);
      const decodeBase64 = CryptoJS.enc.Base64.parse(encryptedData);
      const decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64,
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorId = result.ora_party_id;
            if (
              this.loginResponse.is_new_vendor ||
              this.loginResponse.resetPassword === "Y"
            ) {
              this.$router.push({ name: "Change Password" });
            } else if (!this.loginResponse.is_new_vendor) {
              if (this.vendorId) {
                this.$router.push({ name: "Home" });
              } else {
                this.$router.push({ name: "Party Onboarding" });
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getMenuSubMenuId() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getMenuSubMenuId")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            result.forEach((ele) => {
              if (ele.menu_name === "OnBoarding") {
                if (ele.sub_menu_name === "ADDRESS") {
                  this.$store.dispatch("setAddress", ele);
                  // sessionStorage.setItem("ADDRESS", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "BANK DETAILS") {
                  this.$store.dispatch("setBankDetails", ele);
                  // sessionStorage.setItem("BANK DETAILS", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "COMPLIANCE") {
                  this.$store.dispatch("setCompliance", ele);
                  // sessionStorage.setItem("COMPLIANCE", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "ONBOARDING") {
                  this.$store.dispatch("setOnBoarding", ele);
                  // sessionStorage.setItem("ONBOARDING", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "ADDITIONAL DETAILS") {
                  this.$store.dispatch("setAddItionalDtls", ele);
                  // sessionStorage.setItem("ADDITIONAL DETAILS", JSON.stringify(ele));
                }
              } else if (ele.menu_name === "VendorInvoiceEntry") {
                this.$store.dispatch("setVendorInvoiceEntry", ele);
                sessionStorage.setItem(
                  "VendorInvoiceEntry",
                  JSON.stringify(ele)
                );
              } else if (ele.menu_name === "InvoiceDashboard") {
                this.$store.dispatch("setInvoiceDashboard", ele);
                sessionStorage.setItem("InvoiceDashboard", JSON.stringify(ele));
              } else if (ele.menu_name === "VendorPayment") {
                this.$store.dispatch("setVendorPayment", ele);
                sessionStorage.setItem("VendorPayment", JSON.stringify(ele));
              } else if (ele.menu_name === "PODetails") {
                this.$store.dispatch("setPODetails", ele);
                sessionStorage.setItem("PODetails", JSON.stringify(ele));
              } else if (ele.menu_name === "InvoiceNotBooked") {
                this.$store.dispatch("setInvoiceNotBooked", ele);
                sessionStorage.setItem("InvoiceNotBooked", JSON.stringify(ele));
              } else if (ele.menu_name === "OpenAdvance") {
                this.$store.dispatch("setOpenAdvance", ele);
                sessionStorage.setItem("OpenAdvance", JSON.stringify(ele));
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resetPassword: function() {
      const user_email = this.email;
      if (this.email != "") {
        this.validateEmail();
        if (this.isValid) {
          this.$store
            .dispatch("passwordReset", { user_email })
            .then((resp) => {
              if (resp.status === 200) {
                this.$store.dispatch(
                  "alerts/success",
                  "OTP has been sent to the registered Email"
                );
                this.title = "Forgot Password";
                this.message = "Request completed successfully";
                this.status = "verify";
              }
            })
            .catch((err) => {
              this.error = {
                reset: err,
              };
              this.status = "reset";
            });
        }
      } else {
        this.error = {
          reset: "Email field is required",
        };
      }
    },
    forgotPassword: function() {
      const user = {
        user_email: this.email,
        otp: this.encryptResponse(this.otp),
      };
      this.$store
        .dispatch("confirmOTP", user)
        .then((resp) => {
          if (resp.status === 200) {
            this.status = "setPassword";
            this.$store.dispatch("alerts/success", resp.data.message);
          } else {
            this.$store.dispatch("alerts/error", resp.data.message);
          }
        })
        .catch((err) => {
          this.error = {
            message: err,
          };
        });
    },
    setPassword: function() {
      if (this.password != "" && this.verifyPassword != "") {
        const user = {
          new_password: this.encryptResponse(this.newPass),
          user_email: this.email,
        };
        this.$store
          .dispatch("setNewPassword", user)
          .then((resp) => {
            if (resp.status === 200) {
              this.status = "login";
              this.reset = true;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = "Password is changed successfully";
              this.title = "Login";
              this.password = null;
              this.gmailAuthFlag = true;
              this.newPass = null;
              this.verifyPass = null;
              this.isPasswordExpired = false;
              clearInterval(this.timerIntervalForgot);
              this.$store.dispatch(
                "alerts/error",
                "Password Change Successfully"
              );
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.password = null;
              this.verifyPassword = null;
              this.newPass = null;
              this.verifyPass = null;
              this.gmailAuthFlag = false;
              this.$store.dispatch("alerts/error", resp.data.message);
            }
          })
          .catch((err) => {
            this.error = {
              newPassword: err,
            };
            this.status = "setPassword";
          });
      } else {
        this.error = {
          required: "Password field(s) cannot be empty",
        };
      }
    },
    // getResponsibility(token) {
    //   const headers = new Headers({
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + token,
    //     method: "GET",
    //   });
    //   const options = {
    //     headers: headers,
    //     method: "GET",
    //   };
    //   const url = URL_UTILITY.responsibility;
    //   return fetch(url, options).then((response) =>
    //     response.json().then((json) => {
    //       if (!response.ok) {
    //         return Promise.reject(json);
    //       }
    //       return json;
    //     })
    //   );
    // },
    startTimer(value) {
      this.otpType = "passwordType";
      this.showTimer = true;
      this.showTimerText = true;
      this.timerMinutes = this.storeTime;
      this.timerSeconds = 0;
      if (value === "sendOtp") {
        this.responseMsg = "OTP has been sent to the registered Email";
      } else if (value === "resendOtp") {
        this.responseMsg = "OTP has been resent to the registered Email";
      }
      this.responseMsg = "OTP has been sent to the registered Email";
      this.$store.dispatch("alerts/success", this.responseMsg);
      this.timerInterval = setInterval(() => {
        if (this.timerMinutes === 0 && this.timerSeconds === 0) {
          clearInterval(this.timerInterval);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = "OTP expired";
          this.showTimerText = false;
          this.showTimerText = false;
          this.timerInterval = null;
          setTimeout(() => {
            if (this.timerMinutes === 0 && this.timerSeconds === 0) {
              this.showAlert = false;
            }
          }, 60000);
          setTimeout(() => {
            if (this.timerMinutes === 0 && this.timerSeconds === 0) {
              this.showTimer = false;
              this.$refs.otpInput.clearInput();
            }
          }, 60000);
        } else {
          if (this.timerSeconds === 0) {
            this.timerMinutes -= 1;
            this.timerSeconds = 59;
          } else {
            this.timerSeconds -= 1;
          }
        }
      }, 1000);
    },
    handleOnVerify2FAOTP: function(value) {
      this.error = {
        verify: "",
      };
      this.otp2FA = value;
    },
    handleOnComplete2FAOTP: function(value) {
      this.otp2FA = value;
      this.verify2FAOTP();
    },
    verify2FAOTP: function() {
      const payload = {
        user_email: this.email,
        otp: this.encryptResponse(this.otp2FA),
      };
      this.$store
        .dispatch("verify2faOtp", payload)
        .then((resp) => {
          if (resp.status === 200) {
            let response = this.response;
            sessionStorage.setItem("authToken", response.data.token);
            this.$store.dispatch("setAuthToken", response.data.token);
            if (this.loginResponse.vendor_portal_admin == "PARTY_REQ_ADMIN") {
              sessionStorage.setItem("userType", response.data.user_type);
              this.$store.dispatch("setUserType", response.data.user_type);
              sessionStorage.setItem("userId", response.data.user_id);
              this.$store.dispatch("setUserId", response.data.user_id);
              sessionStorage.setItem(
                "vendorPortalAdmin",
                response.data.vendor_portal_admin
              );
              this.$store.dispatch(
                "setVendorPortalAdmin",
                response.data.vendor_portal_admin
              );
              if (response.data.resetPassword === "Y") {
                sessionStorage.setItem(
                  "resetPassword",
                  response.data.resetPassword
                );
                this.$router.push({ name: "Change Password" });
              } else {
                this.$router.push({ name: "Admin Page" });
              }
            } else {
              sessionStorage.setItem("partyId", response.data.user_ref_id);
              this.$store.dispatch("setPartyId", response.data.user_ref_id);
              sessionStorage.setItem("partyType", response.data.party_type);
              this.$store.dispatch("setPartyType", response.data.party_type);
              sessionStorage.setItem(
                "isNewVendor",
                response.data.is_new_vendor
              );
              this.$store.dispatch(
                "setIsNewVendor",
                response.data.is_new_vendor
              );
              sessionStorage.setItem("userType", response.data.user_type);
              this.$store.dispatch("setUserType", response.data.user_type);
              sessionStorage.setItem("userId", response.data.user_id);
              this.$store.dispatch("setUserId", response.data.user_id);
              sessionStorage.setItem(
                "vendorPortalAdmin",
                response.data.vendor_portal_admin
              );
              this.$store.dispatch(
                "setVendorPortalAdmin",
                response.data.vendor_portal_admin
              );
              if (response.data.user_type == "PARTY") {
                this.$router.push({ path: "/party-dashboard" });
              } else if (response.data.user_type == "VENDOR") {
                this.$router.push({ name: "Home" });
                this.getName();
              } else if (response.data.user_type == "BOTH") {
                sessionStorage.setItem("bothTypeUser", "BOTH");
                this.$router.push({ name: "Routing" });
              }
            }
            this.getMenuSubMenuId();
          } else {
            // alert(response.response.data.message);
            alert(
              "Your entered OTP is incorrect please procced with login again"
            );
            this.showTimer = false;
            clearInterval(this.timerInterval);
            // this.$refs.otpInput.clearInput();
            this.password = null;
          }
        })
        .catch((err) => {
          this.error = {
            message: err,
          };
        });
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value;
    },
    sendOtpType() {
      if (this.otpType === "passwordType") {
        this.sendOTP();
      } else if (this.otpType === "forgotType") {
        this.forgotSendOTP();
      }
    },
    sendOTP() {
      if (this.timerInterval) {
        return;
      }
      this.startTimer("resendOtp");
      this.resend2FAOTP();
    },
    resend2FAOTP() {
      this.showAlert = false;
      const payload = {
        user_email: this.email,
      };
      this.$store
        .dispatch("resend2FAOTP", payload)
        .then((resp) => {
          if (resp.status === 200) {
            this.resend2FAOTPFlag = resp.data;
          }
        })
        .catch(() => {
          // this.error = {
          //   message: err,
          // };
        });
    },
    forgotSendOTP() {
      if (this.timerIntervalForgot) {
        return;
      }
      this.forgotPassStartTimer();
      this.resend2FAOTP();
    },
    forgotPassStartTimer() {
      this.otpType = "forgotType";
      this.showTimerForgot = false;
      this.showTimerForgotText = true;
      this.forgotMinutes = this.storeForgotTime;
      this.forgotSeconds = 0;
      this.timerIntervalForgot = setInterval(() => {
        if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
          clearInterval(this.timerIntervalForgot);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = "OTP expired";
          this.showTimerForgotText = false;
          this.showTimerText = false;
          this.timerIntervalForgot = null;
          setTimeout(() => {
            if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
              this.showAlert = false;
            }
          }, 60000);
          setTimeout(() => {
            if (this.forgotMinutes === 0 && this.forgotSeconds === 0) {
              this.showTimerForgot = true;
              this.status = "login";
              this.$refs.otpInput.clearInput();
            }
          }, 60000);
        } else {
          if (this.forgotSeconds === 0) {
            this.forgotMinutes -= 1;
            this.forgotSeconds = 59;
          } else {
            this.forgotSeconds -= 1;
          }
        }
      }, 1000);
    },
    setNewChangePass: function() {
      if (this.newPass != this.verifyPassword) {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = "Password not match";
        this.isPasswordExpired = false;
        clearInterval(this.timerIntervalForgot);
        this.newPass = null;
        this.verifyPass = null;
        this.gmailAuthFlag = false;
      } else {
        this.setNewPass();
      }
    },
    setNewPass: function() {
      if (this.newPass != "" && this.verifyPass != "") {
        const user = {
          new_password: this.encryptResponse(this.newPass),
          user_email: this.email,
        };
        this.$store
          .dispatch("setNewPassword", user)
          .then((resp) => {
            if (resp.status === 200) {
              this.status = "login";
              // this.reset = true;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = "Password is changed successfully";
              // this.getGmailAuth();
              this.$store.dispatch("alerts/success", this.responseMsg);
              this.title = "Login";
              this.password = null;
              this.gmailAuthFlag = true;
              this.newPass = null;
              this.verifyPassword = null;
              this.isPasswordExpired = false;
              clearInterval(this.timerIntervalForgot);
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              // this.responseMsg = resp.response.data.message;
              this.$store.dispatch("alerts/error", resp.data.message);
              this.newPass = null;
              this.verifyPassword = null;
              this.gmailAuthFlag = false;
            }
          })
          .catch((err) => {
            this.error = {
              newPassword: err,
            };
            this.status = "setPassword";
          });
      } else {
        this.error = {
          required: "Password field(s) cannot be empty",
        };
      }
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
      });
    },
    openConsentModal(flag) {
      this.consentModal = flag;
    },
    acceptConsent() {
      const payload = {
        terms_type: "BGVC_CONSENT",
      };
      // this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getTermsCondition", payload)
        .then((resp) => {
          // this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.consentData = result.terms;
            this.termId = result.term_id;
            // this.termsCondition = result.terms;
          }
        })
        .catch(() => {
          // this.$store.dispatch("shared/setLoader", false);
        });
    },
    addEditTermsCondition() {
      const payload = {
        term_id: this.termId,
        party_req_id: this.partyReqId,
        terms_type: "BGVC_CONSENT",
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditTermsCondition", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.login();
            this.makeToast(response.data.message, "success");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
        });
    },
    handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = "";
      this.$router.push({ name: "Login" });
      sessionStorage.clear();
    },
    selectFile(event) {
      if (event[0].size <= 15728640) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 15 MB.";
      }
    },
    getOpenKmAuthenticate() {
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.uploadFile();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", 374);
        formData.append("sub_menu_id", 88);
        formData.append("record_id", this.partyReqId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              // this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.docDetailId = response.data.data.doc_detail_id;
              this.fileName = response.data.data.original_file_name;
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
    getUploadedDoc() {
      // const userId = sessionStorage.getItem("userId");
      const payload = {
        menu_id: this.menuId,
        sub_menu_id: this.submenuId,
        record_id: this.partyReqId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            if (result) {
              this.fileName = result.original_file_name;
              this.docDetailId = result.doc_detail_id;
              this.isDocumentUploaded = true;
              this.getOpenKmAuthenticate();
            } else {
              this.fileName = null;
              this.docDetailId = null;
              this.isDocumentUploaded = false;
              this.single = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.$emit("updatedList");
              this.getUploadedDoc();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    encryptResponse(payload) {
      const key = "QUtDTUVGWEhKTEtMTVlaUA==";
      const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);

      const encryptedData = CryptoJS.AES.encrypt(payload, keyForCryptoJS, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encryptedData.toString();
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
};
</script>
<style>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}

.otp-input {
  width: 50px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.spinner-postion {
  position: absolute;
  left: 45%;
  top: 30%;
}
.forgot-password {
  text-decoration: underline;
  cursor: pointer;
}
.disabled-resend-otp {
  text-decoration: underline;
  pointer-events: none;
  opacity: 0.6;
}
.disabledPass {
  pointer-events: none;
  opacity: 0.5;
}
.term-text {
  white-space: pre-wrap;
  font-weight: bold;
  overflow-y: scroll;
  height: 400px;
}
.consent {
  font-weight: bold;
}
/* .LoginBtn {
  font-size: 0.9rem;
  background: #034d34;
  padding: 0px 1px 2px;
  text-decoration: none;
  color: #fff;
}
.LoginBtn:hover {
  color: #fff;
  text-decoration: none;
  background: #666;
} */
.news-ticker {
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  color: red;
  font-weight: bold;
  white-space: nowrap;
  padding: 10px 0;
}

.ticker-wrapper {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 20s linear infinite;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.ticker-text {
  white-space: nowrap;
  padding-right: 50px;
}
</style>
