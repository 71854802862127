<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <p
      style="margin-left: 20px; font-weight: bold; font-size: 20px; margin-bottom: 0px;"
    >
      Active Users
    </p>
    <div class="md-layout-item md-small-size-100 md-size-16">
            <div style="margin-top: 10px;">
              <md-button
                class="md-raised md-success search-btn"
                @click="showHideUserModalModal(true,'add')"
                style="margin-left:55rem"
                >Add New User</md-button
              ><md-button
                class="md-raised md-info search-btn"
                style="margin-left:1rem"
                @click="showHideUserModalModal(true,'edit')"
                :disabled="!userFlag"
                >Edit Existing User</md-button
              >
            </div>
          </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            bordered
            sticky-header
            :items="activeUsersData"
            :fields="activeUsersFields"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
          >
          <template #cell(selectRadio)="data">
                  <b-form-radio
                    :key="data.index"
                    name="esc-radios"
                    :value="true"
                    @change="selectedRadio(data.item.selectRadio,
                  data.item,)"
                    v-model="data.item.selectRadio"
                  ></b-form-radio>
          </template>
            <template #cell(start_date)="data">
              <date-picker
                v-if="!data.item.party_req_id"
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="Start Date"
                v-model="data.item.start_date"
                disabled
              ></date-picker>
              <span v-if="data.item.party_req_id">{{
                data.item.start_date
              }}</span>
            </template>
            <template #cell(is_active)="data">
              <md-radio
                v-model="data.item.is_active"
                :value="true"
                disabled
              ></md-radio>
            </template>
            <template #cell(is_admin)="data">
              <b-form-radio
                    :key="data.index"
                    name="escRadios"
                    :value="true"
                    v-model="data.item.is_admin"
                    disabled
                  ></b-form-radio>
            </template>
            <!-- <template #cell(add_row)="data">
              <md-button
                :disabled="data.item.name === null"
                class="md-raised md-info search-btn"
                v-if="data.index === activeUsersData.length - 1"
                @click="addNewUser()"
                >Add</md-button
              >
            </template> -->
            <!-- <template #cell(remove_row)="data">
              <md-button
                class="md-raised md-rose search-btn"
                v-if="
                  data.index === activeUsersData.length - 1 &&
                    data.item.email === null
                "
                @click="removeUser()"
                >Remove</md-button
              >
            </template> -->
            <!-- <template #cell(name)="data">
              <md-input
                v-if="!data.item.party_req_id"
                v-model="data.item.name"
                type="text"
              ></md-input>
              <span v-if="data.item.party_req_id">{{ data.item.name }}</span>
            </template> -->
            <!-- <template #cell(email)="data">
              <md-input
                v-if="!data.item.party_req_id"
                v-model="data.item.email"
                type="text"
              ></md-input>
              <span v-if="data.item.party_req_id">{{ data.item.email }}</span>
            </template> -->
          </b-table>
        </div>
      </md-card>
    </div>
    <p
      style="margin-left: 20px; font-weight: bold; font-size: 20px; margin-bottom: 0px;"
    >
      Inactive Users
    </p>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            bordered
            sticky-header
            :items="inactiveUsersData"
            :fields="inactiveUsersFields"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
          >
            <template #cell(is_active)="data">
              <span>{{ data.item.is_active === "1" ? "Yes" : "No" }}</span>
            </template>
            <template #cell(is_admin)="data">
              <!-- <b-form-checkbox
                v-model="data.item.is_admin"
                :value="true"
                :unchecked-value="false"
                disabled
              ></b-form-checkbox> -->
              <b-form-radio
                    :key="data.index"
                    name="esc-radios"
                    :value="true"
                    v-model="data.item.is_admin"
                    disabled
                  ></b-form-radio>
            </template>
          </b-table>
        </div>
      </md-card>
    </div>

    <b-modal
      v-model="showAddUserModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="sm"
      title="Add/Edit User"
      @hidden="showHideUserModalModal(false,'close')"
      >
      <div class="md-layout-item md-medium-size-100 md-size-18">
            <md-field>
              <label>Email</label>
              <md-input type="email" v-model="userEmail" :disabled="modalValue=='edit'" @input=validateEmail()></md-input>
            </md-field>
            <p v-if="emailFlag" class="error-message">Invalid email address</p>
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-18" style="padding-top: 10px;">
            <md-field>
              <label>Start Date</label>
              <md-input  v-model="startDate" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-100" v-if="modalValue=='edit'" style="margin-top: 60px;">  
            <md-field>
              <label
                style="margin-top:-25%;"
                for="text"
                >End Date</label
              >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="End Date"
                v-model="endDate"
                style="margin-top:-0.5rem;"
                @change="dateSelect()"
                :disabled-date="disabledDates"
              ></date-picker>
            </md-field>
          </div>
          <div class="md-layout-item md-medium-size-100 md-size-0" style="padding-top:10px;">
            <md-field>
              <label>IsAdmin</label> 
            <b-form-checkbox
                v-model="isAdminFlag"
                :value="true"
                :unchecked-value="false"
                style="margin-top: 7px; margin-left:60px;"
                :disabled="this.singleAdmin"
              ></b-form-checkbox>
              </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <div style="margin-top: 10px;">
              <md-button
                class="md-raised md-success search-btn"
                @click="saveUsers()"
                >Save</md-button
              >
            </div>
          </div>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      activeUsersData: [],
      activeUsersFields: [
      {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio',
          variant: 'primary'
        },
        {
          key: "add_row",
          class:'d-none'
        },
        {
          key: "remove_row",
          class:'d-none'
        },
        {
          key: "name",
          class:'d-none'
        },
        {
          key: "email",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
        {
          key: "is_active",
          class:'d-none'
        },
        {
          key: "is_admin",
        },
      ],
      loader: false,
      inactiveUsersData: [],
      inactiveUsersFields: [
        {
          key: "name",
          class:'d-none'
        },
        {
          key: "email",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
        {
          key: "is_active",
          class:'d-none'
        },
        {
          key: "is_admin",
        },
      ],
      oraclePartyId: null,
      selectedAdmin: null,
      sysDate: null,
      showAddUserModal:false,
      userData:null,
      userFlag:false,
      modalValue:null,
      userEmail:null,
      startDate:null,
      endDate:null,
      isAdminFlag:false,
      responseMsg:null,
      partyReqId:null,
      emailFlag:false,
      regexPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      singleAdmin:false,
    };
  },
  mounted() {
    this.getFormattedDate()
    this.oraclePartyId = sessionStorage.getItem("VendorId");
    this.getUsersData();
  },
  methods: {
    getUsersData() {
      const payload = {
        oraPartyId: this.oraclePartyId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getUserManagementData", payload)
        .then((resp) => {
          this.loader = false;
          let result = resp.data.data;
          this.activeUsersData = result.active_users;
          this.inactiveUsersData = result.inactive_users;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewUser() {
      this.activeUsersData.push({
        email: null,
        start_date: this.sysDate,
        end_date: null,
        is_active: 1,
        is_admin: false,
      });
    },
    removeUser() {
      this.activeUsersData.pop();
    },
    changeIsAdmin(index) {
      for (let i of this.activeUsersData) {
        i.is_admin = false;
      }
      this.activeUsersData[index].is_admin = true;
      setTimeout(() => {
        this.activeUsersData = JSON.parse(JSON.stringify(this.activeUsersData));
      }, 500);
    },
    getFormattedDate() {
      const date = new Date();

      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = date
        .toLocaleString("en-US", { month: "short" })
      const year = date.getFullYear();

      this.sysDate = `${day}-${month}-${year}`;
    },
    showHideUserModalModal(flag,value){
      this.showAddUserModal=flag
      this.modalValue=value
      if(this.modalValue=='add'){
        this.startDate=this.sysDate;
        this.userEmail=null;
        this.isAdminFlag=false;
        this.endDate=null;
        this.partyReqId=null;
        this.singleAdmin=false;
      }
      else if(this.modalValue=='edit'){
        this.userEmail=this.userData.email;
        this.startDate=this.userData.start_date;
        this.isAdminFlag=this.userData.is_admin;
        if(this.userData.is_admin){
          this.singleAdmin=this.userData.is_admin
        }
        this.endDate=this.userData.end_date;
        if(this.endDate){
          this.singleAdmin=true
        }
        this.partyReqId=this.userData.party_req_id;        
      }
      if(!flag){
        this.emailFlag = false;
        this.singleAdmin=false;
      }
    },
    selectedRadio(flag,item) {
      if(flag){
        this.userData=item
        this.userFlag=true
      }      
    },
    disabledDates(date) {
      const restrictedFromDate = new Date(
      );
      restrictedFromDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedFromDate;

      //  restrictedFromDate.setHours(0, 0, 0, 0);
      // const restrictedToDate = new Date();
      // restrictedToDate.setHours(0, 0, 0, 0);
      // return date < restrictedFromDate || date > restrictedToDate;
    },
    postUsersData() {
      const payload = {
        oraPartyId: this.oraclePartyId,
          party_req:[{
          party_req_id:0,
          email:this.userEmail,
          is_admin:this.isAdminFlag
        }]
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/postUserManagementData", payload)
        .then((resp) => {
          this.loader = false;
          if(payload.party_req[0].is_admin==true && !this.singleAdmin){
          alert("You have been removed from your admin role. Please log in again to continue.") 
          this.$store.dispatch("logout");
          }else{            
          this.responseMsg = resp.data.message;          
          this.makeToast(this.responseMsg, "success")  
          this.showAddUserModal=false
          this.getUsersData()
          }
        })
        .catch(() => {
          this.loader = false;
        });

    },
    putUsersData() {
      const payload = {
          party_req:[{
          party_req_id:this.partyReqId,
          end_date:this.endDate,
          is_admin:this.isAdminFlag
        }]
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/putUserManagementData", payload)
        .then((resp) => {
          this.loader = false;
          if(payload.party_req[0].is_admin==true && !this.singleAdmin){
            alert("You have been removed from your admin role. Please log in again to continue.") 
            this.$store.dispatch("logout");
          }else{
          this.responseMsg = resp.data.message;          
          this.makeToast(this.responseMsg, "success")  
          this.showAddUserModal=false
            this.getUsersData()
          }
        })
        .catch(() => {
          this.loader = false;
        });

    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: "Alert",
        variant: variant,
        solid: true,
      });
    },
    saveUsers() {
      if(this.modalValue=='add'){
        this.postUsersData()
      }else if(this.modalValue=='edit'){
        this.putUsersData()
      }
    },
    validateEmail(){
         if(this.regexPattern.test(this.userEmail)){
          this.emailFlag = false;
         }else{
          this.emailFlag=true
         }
      },
      dateSelect(){
        if(this.endDate){
            this.isAdminFlag=this.userData.is_admin;
          this.singleAdmin=true;
            if(this.isAdminFlag){
            this.makeToast("Kindly assign admin rights to other user first", "warning")
            this.endDate=null;
          }
        }else{
          if(this.userData.is_admin){
            this.singleAdmin=true;
          }else{
          this.isAdminFlag=this.userData.is_admin;
          this.singleAdmin=false
          }
        }
      }
  },
};
</script>

<style>
  .error-message {
    color: red;
  }
</style>
